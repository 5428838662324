import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadPayComponent } from 'src/app/admin/pages/modules/plazza/access/load-pay/load-pay.component';
import { OpenPayPopupComponent } from 'src/app/admin/pages/modules/plazza/access/open-pay-popup/open-pay-popup.component';
import { SnackBarService } from 'src/app/admin/services/system/snackbar.service';
import { DialogCustomComponent } from 'src/app/dialog-custom/dialog-custom.component';
import { ProfileService } from 'src/app/services/cliente/profile.service';
import { ProductLoadPayComponent } from '../product-load-pay/product-load-pay.component';
import { ProductOpenPayPopupComponent } from '../product-open-pay-popup/product-open-pay-popup.component';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from 'src/app/services/auth.service';
import { MethodPayService } from 'src/app/services/both/methodPay.service';
import { DiloLoadPayComponent } from 'src/app/shared/dilo-load-pay/dilo-load-pay.component';
import { PlazzaService } from 'src/app/services/plazza.service';
import { BasaLoadPayComponent } from 'src/app/shared/basa-load-pay/basa-load-pay.component';
import { ModalPendingComponent } from 'src/app/shared/modal-pending/modal-pending.component';

function expDateValidators(c: FormControl) {
  const monthAndYear = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
  let expired = false
  if (c.value != null && c.value.length == 4) {
    // get current year and month
    let d = new Date();
    let currentYear = d.getFullYear();
    let currentMonth = d.getMonth() + 1;
    // get parts of the expiration date
    let part1 = c.value.substring(0, 2);
    let part2 = c.value.substring(2, 4);
    let year = parseInt(part2, 10) + 2000;
    let month = parseInt(part1, 10);
    // compare the dates
    if (year < currentYear || (year == currentYear && month < currentMonth)) {
      expired = true
    }
  }
  return (monthAndYear.test(c.value)
    && !expired) ? null
    : {
      validateInput: {
        valid: false,
      },
    };
}

@Component({
  selector: 'app-product-payment',
  templateUrl: './product-payment.component.html',
  styleUrls: ['./product-payment.component.scss']
})
export class ProductPaymentComponent implements OnInit {

  public form: FormGroup

  order_id = ''

  // CREDIT CARD
  creditForm: FormGroup;
  typeCarSelected = 'default' // auxiliar para ver que tarjeta selecone
  mask = '0000 0000 0000 0000'

  // FIDELIZATION

  totalDiscount = 0; // este es el total a descontar;

  currentPoints = 0;
  totalPoints;
  limitPoints = 0;
  viewDiscountPoints = 0; // sera mi auxiliar

  _coupon = null;
  /* { "id": 1, "uniqueCode": "F799510685", "type": "0", "value": "120", "dateStart": "2021-10-25", "dateEnd": "2021-11-30", "maxusedbyuser": "1", "maxcoupons": "1", "idDelete": "0", "active": "1" } */

  _couponText = null;
  _point = null;

  _wallet = null;
  //end fid

  service_id = ''
  service: any;
  retention: any;
  retention_new_data:any = null
  card_type: any;
  points_used: number = 0;

  codeOrder = null

  precio = null
  precio_descuento = null
  cupon = null;
  precio_total = null
  puntos_acumular = 0

  precio_cupon = null

  puntosUser = 0;

  domain = environment.domain

  //dilo
  infoPhone = null

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductPaymentComponent>, 
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private profileService: ProfileService,
    private authService: AuthService,
    private methodPayService: MethodPayService,
    public plazzaService: PlazzaService,
    public snackBar: MatSnackBar,
    private router: Router,
    private matDialogService: MatDialog,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
    
    ) {
      this.creditForm = this.formBuilder.group({
        /*CardNumber: [null, [Validators.required, Validators.minLength(15), Validators.maxLength(16)]],
        BillToFirstName: [null, [Validators.required]],
        //BillToLastName: [null, [Validators.required]],
        CardCVV2: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
        CardExpiryDate: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), expDateValidators]],*/
        pay: ['0', Validators.required],
        Coupon: [null, []],
        Points: [null, []],
        Bin: [null, []],
        Invoice: [false, []],
      });
    }

  ngOnInit() {
    console.log('datos para el metodo de pago', this.data)
    this.service_id = this.data.id_servicio
    this.service = this.data.data
    this.retention = this.data.retention
    this.retention_new_data = this.data.retention_data
    this.initPayment()
    this.ongetPuntos()
    this.getNumberToDilo()
  }

  initPayment(){
    if(this.retention.retentions == null){
      this.precio = this.service.precio
      this.precio_total = this.service.precio
      this.puntos_acumular = 0
      if(this.service?.tipo_promocion == 1){
        this.precio_descuento = this.precio - this.service?.precio_descuento;
        this.precio_total = this.service?.precio_descuento
      } else if(this.service?.tipo_promocion == 2){
        this.puntos_acumular = this.service?.puntos_acumular
      }
    } else if(this.retention.retentions) {
      this.precio = this.retention_new_data.precio_final
      if(this.retention_new_data?.tipo_promocion == 1){
        this.precio_descuento = this.precio - this.retention_new_data?.precio_descuento;
        this.precio_total = this.retention_new_data?.precio_descuento
      } else if(this.retention_new_data?.tipo_promocion == 2){
        this.puntos_acumular = this.retention_new_data?.puntos_acumular
      }

      //Llenando cupon (solo ocurre en guru)
      this.creditForm.get('Coupon').setValue(this.retention_new_data?.serie_cupon)
    }
  }

  getTypeCreditCard(number) {
    var insertedCardType = 'default';

    var cardsTypes = [
      { type: 'visa', regex: /^4/, tarjeta_fabricante: 1},
      { type: 'mastercard', regex: /^5[1-5]/, tarjeta_fabricante: 1 },
      { type: 'american-express', regex: /^3[47]/, tarjeta_fabricante: 2},
      /*{ type: 'diners', regex: /^30[0-5]/ },
      { type: 'jcb', regex: /^35(2[89]|[3-8][0-9])/ },
      { type: 'visa-electron', regex: /^(4026|417500|4508|4844|491(3|7))/ },
      { type: 'maestro', regex: /^(5000|5018|5020|5038|6304|6759|676[1-3])/ },
      { type: 'discover', regex: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/ }*/
    ];
    if (number) {
      cardsTypes.forEach(ct => {
        if (ct.regex.test(number.substring(0, 6))) {
          insertedCardType = ct.type;
          this.card_type = ct.tarjeta_fabricante;
          if (ct.type == 'american-express') {
            this.mask = '0000 0000 0000 000'
          } else {
            this.mask = '0000 0000 0000 0000'
          }
        }
      });
    }
    return insertedCardType;
  }

  

  yes(element) {
    //this.dialogRef.close("yes");
    //this.dialogRef.close("success");

    if(this.creditForm.value.pay == '0'){
      this.verificarBasa()
    }else{
      if(this.infoPhone == null){
        this.snackBar.open('Por favor, complete los datos', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });          
        return
      }
      this.payCarritoDilo()
    }
  }
  no(element) {
    //this.dialogRef.close("no");
    this.dialogRef.close("cancel");

  }

  initRetention(){
    this.creditForm.get('Coupon').setValue(this.retention_new_data?.serie_cupon)
  }

  verificarBasa(){
    this.methodPayService.verifyBasa(this.authService.currentUserValue.id_sesion).subscribe((res:any) => {
      if(res.length > 0){
        this.getModalPending(res)
      }else{
        this.payment()
      }
    }, error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  getModalPending(list){
    const dialogRef = this.dialog.open(ModalPendingComponent, {
      width: "700px",
      data: list,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'close' || result === undefined) {
      } else if(result == 'success') {
        this.payment()
      }
    });
  }
  
  payment(){
    //Falta traer id de guru en cotizacion
    
    let send = {
      id_servicio: this.service_id,
      /*nombre: this.creditForm.value.BillToFirstName, //en el manual dice nombre pero en el postman no se envia
      numero: this.creditForm.value.CardNumber,
      cvv: this.creditForm.value.CardCVV2,
      mes_expiracion: this.creditForm.value.CardExpiryDate.substring(0, 2) + '/' + this.creditForm.value.CardExpiryDate.substring(2),*/
      puntos: this.creditForm.value.Points ? this.creditForm.value.Points : 0,
      cupon: this.cupon ? this.cupon : '',
      //tarjeta_fabricante: this.card_type,
      necesita_factura: this.creditForm.value.Invoice ? 1 : 0
    }
    console.log(this.creditForm.value.CardExpiryDate)

    /*
      id:1->12.5%
      id:2->25%
      id:3->1%
      id:4->ISV
    */
    if(this.retention_new_data != null){
      this.retention.retentions.forEach(e => {
        switch(e.retention_id) { 
          case 1: { 
            //let auxRetention125 = { archivo_12: e.file.fileName, contenido_12: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetention125 }
            send['archivo_12'] = e.file.fileName
            send['contenido_12'] = e.file.base64.split("base64,")[1]
            break;
          } 
          case 2: { 
            //let auxRetention25 = { archivo_25: e.file.fileName, contenido_25: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetention25 }
            send['archivo_25'] = e.file.fileName
            send['contenido_25'] = e.file.base64.split("base64,")[1]
            break; 
          }
          case 3: { 
            //let auxRetention1 = { archivo_1: e.file.fileName, contenido_1: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetention1 }
            send['archivo_1'] = e.file.fileName
            send['contenido_1'] = e.file.base64.split("base64,")[1]
            break; 
          } 
          case 4: { 
            //let auxRetentionISV = { archivo_isv: e.file.fileName, contenido_isv: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetentionISV }
            send['archivo_isv'] = e.file.fileName
            send['contenido_isv'] = e.file.base64.split("base64,")[1]
            break; 
          } 
          default: { 
            //statements; 
            break; 
          } 
        }
      });
    }

    console.log('datos para comprar', send)

    let dataP = new FormData()
    
    for(let key in send){  
      dataP.append(key,send[key])
    }

    
    this.profileService.compraDirecta(dataP).subscribe((res:any)=>{
      console.log(res)
      /*if(res != null && res.respuesta == 0){
        this.snackBarService.success({ message: 'Pago realizado con éxito.'})
        this.router.navigate(['/cuenta/orders/' + res?.orden])
      } else if(res.respuesta == 2){
        //viene razon, orden, html
        if(res.html != undefined){
          this.codeOrder = res.orden
          this.openPayPopup(res.html)
        }
      }*/
      if(res.respuesta == 0){ //link de basa creado
        this.validateCompraBasa(res)
      }else{
        this.snackBar.open(res.razon, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, error =>{
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  openPayPopup(html){

    let price = 0
    if(this.retention?.retentions == null){
      if(this.service?.descuento != null){
        price = this.service?.precio_descuento
      } else {
        price = this.service?.precio
      }
    } else {
        price = this.retention_new_data?.precio_descuento
    }

    console.log(this.retention, this.service, this.retention_new_data)
    console.log('precio',price)

    const dialogRef = this.matDialogService.open(ProductOpenPayPopupComponent, {
      data: { html: html, precio: price },
      width: "500px",
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
      }
      if (result == 'success') {
        this.openLoadPay()
      }
    })
  }

  openLoadPay() {
    const dialogRef = this.matDialogService.open(ProductLoadPayComponent, {
      data: this.codeOrder,
      width: "500px",
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'SUCCESS') {
        this.snackBarService.success({message: `Operación ejecutada con éxito.`});
        this.dialogRef.close('success')
        this.router.navigate(['/cuenta/orders/' + this.codeOrder])
      }
    })
  }

  validateCompraBasa(basa){
    const dialogRef = this.dialog.open(BasaLoadPayComponent, {
      width: "700px",
      data: { basa, type:'User'},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.state == 'SUCCESS') {
        this.snackBarService.success({message: `Operación ejecutada con éxito.`});
        this.dialogRef.close('success')
        this.router.navigate(['/orden-finalizado/' + result.referencia])
      }
    });
  }

  verificarPromo(text, product){
    /*if(!this.user){
      this.router.navigate(['/ingresar'], { queryParams: { returnUrl: this.router.routerState.snapshot.url} });
      return
    }*/

    let send = {
      id_cotizacion: this.service_id,
    }

    if(text == 'Cupon'){
      send['cupon'] = this.creditForm.value.Coupon
      /*if( this.retention.retentions != null ){
        if(this.creditForm.value.Coupon == this.retention_new_data.serie_cupon){
          this.cupon = this.creditForm.value.Coupon
          this.precio_cupon = this.retention_new_data.precio_final - this.retention_new_data.precio_descuento
          this.precio_total = this.retention_new_data.precio_descuento
          return;
        }
      }*/
    }else if(text == 'Puntos'){
      send['puntos_utilizar'] = this.creditForm.value.Points
    }else if(text == 'Bin'){
      send['bin'] = this.creditForm.value.Bin
    }

    if(this.retention_new_data != null){
      this.retention.retentions.forEach(e => {
        switch(e.retention_id) { 
          case 1: {
            send['archivo_12'] = 1
            break;
          } 
          case 2: {
            send['archivo_25'] = 1
            break; 
          }
          case 3: { 
            send['archivo_1'] = 1
            break; 
          } 
          case 4: { 
            send['archivo_isv'] = 1
            break; 
          } 
          default: { 
            //statements; 
            break; 
          } 
        }
      });
    }

    let dataP = new FormData()
    for(let key in send){  
      dataP.append(key,send[key])
    }

    this.profileService.verificarPromo(dataP).subscribe((data: any) => {
      if(data?.promo != 0){
        if(data?.tipo_promocion == 1){
          this.precio_descuento = this.precio - this.data?.precio;
          this.precio_total = this.data?.precio
        } else if(data?.tipo_promocion == 2){
          this.puntos_acumular = this.data?.puntos
        }
      }else{
        this.snackBar.open(`Ingrese ${text} válido(s)`, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    },
      error => {
        this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  removeCoupon(){
    this.cupon = null
  }

  ongetPuntos(){
    this.profileService.getPuntos().subscribe((res:any) =>{
      this.puntosUser = res
    },
    error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  getNumberToDilo(){
    this.profileService.getProfile(this.authService.currentUserValue.id_sesion).subscribe((res:any) => {
      let phoneModel = res.celular ? res.celular.slice(3) : ''
      this.infoPhone = res.celular ? (res.celular.startsWith('504') ? phoneModel : null) : null //dilo es solo honduras
    },error =>{
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  payCarritoDilo(){
    let send = {
      celular: this.infoPhone, //'88474176'
      id_servicio: this.service_id,
      puntos: this.creditForm.value.Points ? this.creditForm.value.Points : 0,
      cupon: this.cupon ? this.cupon : '',
      necesita_factura: this.creditForm.value.Invoice ? 1 : 0
    }

    if(this.retention_new_data != null){
      this.retention.retentions.forEach(e => {
        switch(e.retention_id) { 
          case 1: { 
            //let auxRetention125 = { archivo_12: e.file.fileName, contenido_12: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetention125 }
            send['archivo_12'] = e.file.fileName
            send['contenido_12'] = e.file.base64.split("base64,")[1]
            break;
          } 
          case 2: { 
            //let auxRetention25 = { archivo_25: e.file.fileName, contenido_25: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetention25 }
            send['archivo_25'] = e.file.fileName
            send['contenido_25'] = e.file.base64.split("base64,")[1]
            break; 
          }
          case 3: { 
            //let auxRetention1 = { archivo_1: e.file.fileName, contenido_1: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetention1 }
            send['archivo_1'] = e.file.fileName
            send['contenido_1'] = e.file.base64.split("base64,")[1]
            break; 
          } 
          case 4: { 
            //let auxRetentionISV = { archivo_isv: e.file.fileName, contenido_isv: e.file.base64.split("base64,")[1] }
            //send = {...send, ...auxRetentionISV }
            send['archivo_isv'] = e.file.fileName
            send['contenido_isv'] = e.file.base64.split("base64,")[1]
            break; 
          } 
          default: { 
            //statements; 
            break; 
          } 
        }
      });
    }

    let formDilo = new FormData()
    for (const key in send) {
      formDilo.append(key, send[key])
    }

    this.methodPayService.payCarritoDilo(this.authService.currentUserValue.id_sesion,formDilo).subscribe((res:any) => {
      if(res.respuesta == 0){ //es correcto y se genera el link
        this.validateCompraDilo(res.info_dilo)
        //this.listDilo = res.ordenes ? res.ordenes : []
      }else{
        this.snackBar.open(res.razon, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  validateCompraDilo(dilo){
    const dialogRef = this.dialog.open(DiloLoadPayComponent, {
      width: "700px",
      data: { dilo, type:'User'},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.state == 'SUCCESS') {
        this.dialogRef.close("success");
        this.router.navigate(['/cuenta/orders/'])
      }else{
      }
    });
  }
}

  