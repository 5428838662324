<mat-dialog-content style="max-height: 57vh;">
    <div style="padding: 20px;">
        <div fxLayout="column">
            <div *ngIf="this.state == 'LOAD'" class="text-center">
                <div fxFlex="100" style="text-align: -webkit-center;">
                    <mat-spinner [diameter]="70"></mat-spinner>
                </div>
                <div fxFlex="100" class="mt-10">
                    <h2>Procesando pago, por favor espere ...</h2>
                </div>
            </div>
            <div *ngIf="this.state == 'SUCCESS'" fxLayoutAlign="center center" class="text-center">
                <div fxFlex="100">
                    <mat-icon style="font-size: 73px; height: auto; width: auto; color:rgb(22, 185, 49)">check_circle</mat-icon>
                </div>
                <div fxLayout="column" fxFlex="100" class="mt-10">
                    <h4>Pago realizado con éxito.</h4>
                    <div fxFlex="100" class="mt-10 text-left">
                        <span>
                            <b>Número de Referencia: </b> {{this.reference}} <br>
                            <b>Total: </b> L. {{this.total | number : '1.2-2'}}
                        </span>
                    </div>
                </div>
                <div fxFlex="100" class="mt-10">
                    <button color="primary" mat-raised-button (click)="closeSuccess()">ACEPTAR</button>
                </div>
            </div>
            <div *ngIf="this.state == 'PENDING'" fxLayoutAlign="center center" class="text-center">
                <div fxFlex="100">
                    <mat-icon style="font-size: 73px; height: auto; width: auto; color:rgb(185, 106, 22)">assignment_late</mat-icon>
                </div>
                <div fxLayout="column" fxFlex="100" class="mt-10">
                    <h4>Pendiente de Aprobación.</h4>
                    <div fxFlex="100" class="mt-10 text-left">
                        <span>
                            <b>Número de Referencia: </b> {{this.reference}} <br>
                            <b>Total: </b> L. {{this.total | number : '1.2-2'}}
                        </span>
                    </div>
                    <div fxFlex="100" class="mt-10">
                        La transacción está siendo verificada, puede llegar a tardar a ser visualizado en las próximas 24 horas.
                    </div>
                </div>
                <div fxFlex="100" class="mt-10">
                    <button color="primary" mat-raised-button (click)="closeSuccess()">ACEPTAR</button>
                </div>
            </div>
            <div *ngIf="this.state == 'ERROR'" fxLayoutAlign="center center" class="text-center">
                <div fxFlex="100">
                    <mat-icon style="font-size: 73px; height: auto; width: auto;" color="warn">error</mat-icon>
                </div>
                <div  fxLayout="column" fxFlex="100" fxLayout="column" class="mt-10">
                    <!--<h4>{{this.messageError}}</h4>-->
                    <h4>Transacción Cancelada/Rechazada</h4>
                    <div fxFlex="100" class="mt-10 text-left">
                        <span>
                            <b>Número de Referencia: </b> {{this.reference}} <br>
                            <b>Total: </b> L. {{this.total | number : '1.2-2'}}
                        </span>
                    </div>
                </div>
                <div fxFlex="100" class="mt-10">
                    <button color="primary" mat-raised-button (click)="closeSuccess()">VOLVER A INTENTAR</button>
                </div>
            </div>
            <div *ngIf="this.state == 'EXPIRED'" class="text-center">
                <div fxFlex="100">
                    <mat-icon style="font-size: 73px; height: auto; width: auto;" color="warn">assignment_late</mat-icon>
                </div>
                <div fxFlex="100" class="text-center mt-10">
                    <h4>Tiempo de espera excedido, el pago no fue procesado. Por favor verifique que su navegador no bloquee
                    ventanas emergentes.
                    </h4>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlex="100" class="mt-10">
                    <button ngClass.xs="mt-10" color="primary" mat-raised-button (click)="closeSuccess()">VOLVER A
                    INTENTAR</button>
                </div>
            </div>
        </div>
    </div>
  
</mat-dialog-content>