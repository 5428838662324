import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './core/authentication/auth.guard';
import { AdminGuard } from './core/authentication/admin.guard';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'cuenta', canActivate: [AuthGuard], loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Cuenta' } },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
            { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Wishlist' } },
            { path: 'carrito', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Carrito' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'orden-finalizado', loadChildren: () => import('./pages/order-finalized/order-finalized.module').then(m => m.OrderFinalizedModule), data: { breadcrumb: 'Orden Finalizado' } },
            { path: 'alianzas-estratégicas', loadChildren: () => import('./pages/strategic-alliance/strategic-alliance.module').then(m => m.StrategicAllianceModule), data: { breadcrumb: 'Alianzas Estratégicas' } },
            { path: 'fundacion', loadChildren: () => import('./pages/foundation/foundation.module').then(m => m.FoundationModule), data: { breadcrumb: 'Fundación' } },
            { path: 'consulta-asesor', loadChildren: () => import('./pages/consultant-advice/consultant-advice.module').then(m => m.ConsultantAdviceModule), data: { breadcrumb: 'Fundación' } },
            { path: 'terminos-condiciones', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule), data: { breadcrumb: 'Terminos y Condiciones' } },
            { path: 'como-funciona', loadChildren: () => import('./pages/how-work/how-work.module').then(m => m.HowWorkModule), data: { breadcrumb: 'Como Funciona' } },
            { path: 'nosotros', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'Quiénes Somos' } },
            { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule), data: { breadcrumb: 'Preguntas Frecuentes' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contáctanos' } },
            { path: 'ingresar', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Inicio de Sesión ' } },
            { path: 'registrar', loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule), data: { breadcrumb: 'Regístrate ' } },
            { path: 'categorías', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Categorías' } },
            { path: 'store', loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule), data: { breadcrumb: 'Tiendas' } },
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'Productos' } },
            { path: 'política-privacidad', loadChildren: () => import('./pages/privacity/privacity.module').then(m => m.PrivacityModule), data: { breadcrumb: 'Política de Privacidad' } }
        ]
    },
    { path: 'auth-verificar', loadChildren: () => import('./verification-auth/verification-auth.module').then(m => m.VerificationAuthModule) },
    { path: 'admin', canActivate: [AdminGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabled'  // for one load page, without reload
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }